<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          :is="curBottomComponent" :onlyread="true" :id="detailId" :isManage="true"  @on-detail="showDetail"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  data () {
    return {
      pageType: 'list',
      detailId: 0
    }
  },
  components: {
    Container,
    // 客户列表
    listComponent: () => import('@/components/advertiser/CustomerList'),
    detailComponent: () => import('@/components/advertiser/CustomerDetail')
  },
  methods: {
    showDetail (id) {
      this.detailId = id
      this.pageType = 'detail'
      this.initComponents()
    },
    initTagEvent () {
    // 注册头部事件
      this.setLinkRouterMaps(['advertiserList', () => {
        this.detailId = 0
        this.pageType = 'list'
        this.initComponents()
      }])
    },

    initComponents () {
      // 清空便签项
      this.setLinkTagArray()
      this.setRightComponent(null)
      this.setShowRightBlock(false)
      this.setLeftComponent(null)
      this.setShowLeftBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLinkTagArray({ key: 'advertiserList', value: '客户列表' })

      switch (this.pageType) {
        case 'list':
          this.detailId = 0
          this.setBottomComponent('listComponent')
          this.setActivedTagName('advertiserList')
          break
        case 'detail':
          this.setBottomComponent('detailComponent')
          this.setLinkTagArray({ key: 'advertiserDetail', value: '客户详情' })
          this.setActivedTagName('advertiserDetail')
          break
        default:
          break
      }
    }
  },
  mounted () {
    this.initComponents()
    this.initTagEvent()
  }
}
</script>
